import firebase from 'firebase/app';
import firestoreemail from '../firestoreemail'
import React, { Component } from "react";
import "../styles/footerstyle.css";
import "../styles/commonstyle.css";

class FooterComponent extends Component {
    constructor(){
        super();
        this.state = {
          email:""
        }
      }
    
      updateInput = e => {
        this.setState({
          [e.target.name]: e.target.value
        });
      }
    
      addEmail = e => {
        e.preventDefault();
        const db = firebase.firestore();
        
        const userRef = db.collection("email").add({
          email: this.state.email
        });  
        this.setState({
          email: ""
        });
      };
    
    render(){
        return(
            <div >
                <section className="subscribe-area pb-50 pt-70">
        <div className="container">
              <div className="row">

              <div className="col-md-4">
                <div className="subscribe-text mb-15">
                  <h2>Subscribe to newsletter</h2>
                </div>
              </div>
              <div className="col-md-8">
                <div className="subscribe-wrapper subscribe2-wrapper mb-15">
                  <div className="subscribe-form">
                    <form onSubmit={this.addEmail}>
                      <input placeholder="Enter your email address"
                       type="email" name="email"
                        onChange={this.updateInput}
                        value={this.state.email}/>
                    <button type="submit">Subscribe <i className="fa fa-telegram-plane"></i></button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-center bottom">
						<div className="copyright">
							<p>© <span>2019</span> <a href="/" className="transition">Chetan Gautam Bendre</a> All rights reserved.</p>
						</div>
					</div>
            </div>
    </section>

            </div>
        );
    }
} 

export default FooterComponent;
   