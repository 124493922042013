import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Route from "react-router-dom/Route";
import "./App.css";
import HeaderComponent from "./components/header";
import NewsComponent from "./components/news";
import AboutComponent from "./components/about";
import ConnectComponent from "./components/connect";
import PoliticalComponent from "./components/political";
import ProfessionalComponent from "./components/professional";
import SocialComponent from "./components/social";
import GalleryComponent from "./components/gallery";
import HomeComponent from "./components/home";
import FooterComponent from "./components/footer";
import QuoteComponent from "./components/quotes";
class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <HeaderComponent />
          <Route path="/quotes" exact strict component={QuoteComponent} />
          <Route path="/" exact strict component={HomeComponent} />
          <Route path="/about" exact strict component={AboutComponent} />
          <Route path="/news" exact strict component={NewsComponent} />
          <Route path="/social" exact strict component={SocialComponent} />
          <Route
            path="/political"
            exact
            strict
            component={PoliticalComponent}
          />
          <Route
            path="/professional"
            exact
            strict
            component={ProfessionalComponent}
          />
          <Route path="/gallery" exact strict component={GalleryComponent} />
          <Route path="/connect" exact strict component={ConnectComponent} />
          <FooterComponent/>
        </div>
      </Router>
    );
  }
}

export default App;
