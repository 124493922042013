import React, { Component } from "react";
import "../styles/headerstyle.css";
import "../styles/commonstyle.css";
import { Link } from "react-router-dom";

class HeaderComponent extends Component {
  state = {
    tabs: [
      "Home",
      "About",
      "News",
      "Social",
      "Political",
      "Professional",
      "Gallery",
      "Connect"
    ],
    href_link: [
      ["http://fb.com/chetan.bendre", "fa fa-facebook"],
      ["http://twitter.com/chetan_g_bendre", "fa fa-twitter"],
      ["https://www.linkedin.com/in/chetan-bendre/", "fa fa-linkedin"],
      ["http://instagram.com/chetan.bendre", "fa fa-instagram"]
    ]
  };
  render() {
    return (
      <div className="fixed-top">
        <header className="topbar">
          <div className="container">
            <div className="row">
              {/* <!-- social icon--> */}
              <div className="col-sm-12">
                <ul className="social-network">
                  {this.state.href_link.map(tag => (
                    <li key={tag[1]}>
                      <a
                        className="waves-effect waves-dark"
                        href={tag[0]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <i className={tag[1]} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </header>
        <nav className="navbar navbar-expand-lg navbar-dark mx-background-top-linear">
          <div className="container">
            <Link className="navbar-brand" to="/">
              {" "}
              Chetan Gautam Bendre
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                {this.state.tabs.map(tag => (
                  <li className="nav-item" key={tag}>
                    <Link
                      className="nav-link "
                      to={`${tag === "Home" ? "/" : tag}`}
                    >
                      {tag}
                      <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default HeaderComponent;
