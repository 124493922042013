import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

const settings = {};

const config = {
    apiKey: "AIzaSyBbZxAnWuqGkHaNbJZHmJmRXwWmLaEFmOs",
    authDomain: "new-chetan-website.firebaseapp.com",
    databaseURL: "https://new-chetan-website.firebaseio.com",
    projectId: "new-chetan-website",
    storageBucket: "new-chetan-website.appspot.com",
    messagingSenderId: "956970802211"};
firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;