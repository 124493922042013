import React, { Component } from "react";
import "../styles/homestyle.css";
import m1 from "../img/m1.jpg";
import m2 from "../img/m2.jpg";
import m3 from "../img/m3.jpg";
import m4 from "../img/m4.jpg";
import e1 from "../img/e1.jpg";
import e2 from "../img/e2.jpg";
import e3 from "../img/e3.jpg";
import e4 from "../img/e4.jpg";


class QuotesComponent extends Component {
  
    render() {
        return (
          <div>
            {/* <!--Container--> */}
    <div className="container-fluid top_bottom_margin">
        <div className="row ">
            {/* <!--Start include wrapper--> */}
            <div className="include-wrapper pb-5 col-12">
                {/* <!--SECTION START--> */}
                <section className="row">
                    {/* <!--Start slider news--> */}
                    <div className="col-12 col-md-6 pb-0 pb-md-3 pt-2 pr-md-1">
                        <div id="featured1" className="carousel slide carousel" data-ride="carousel">
                            {/* <!--slider navigate--> */}
                            <ol className="carousel-indicators top-indicator">
                                <li data-target="#featured1" data-slide-to="0" className="active"></li>
                                <li data-target="#featured1" data-slide-to="1"></li>
                                <li data-target="#featured1" data-slide-to="2"></li>
                                <li data-target="#featured1" data-slide-to="3"></li>
                            </ol>
                            
                            {/* <!--carousel inner--> */}
                            <div className="carousel-inner">
                                {/* <!--Item slider--> */}
                                <div className="carousel-item active">
                                    <div className="card border-0 rounded-0 text-light overflow zoom">
                                        {/* <!--thumbnail--> */}
                                        <div className="position-relative">
                                            {/* <!--thumbnail img--> */}
                                            <div className="ratio_left-cover-1 image-wrapper">
                                            <img className="img-fluid w-100 h-100"
                                                         src={m1}
                                                         alt="Image description"/>
                                            </div>
                                            
                                            {/* <!--title--> */}
                                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                                {/* <!--title and description--> */}
                                                <a href="/">
                                                    <h2 className="h3 post-title text-white my-1"></h2>
                                                </a>
    
                                                {/* <!-- meta title --> */}
                                                <div className="news-meta">
                                                    <span className="news-author"> <a className="text-white font-weight-bold" href="/"></a></span>
                                                    <span className="news-date"></span>
                                                </div>
                                            </div>
                                            {/* <!--end title--> */}
                                        </div>
                                        {/* <!--end thumbnail--> */}
                                    </div>
                                </div>
                                
                                {/* <!--Item slider--> */}
                                <div className="carousel-item">
                                    <div className="card border-0 rounded-0 text-light overflow zoom">
                                        {/* <!--thumbnail--> */}
                                        <div className="position-relative">
                                            {/* <!--thumbnail img--> */}
                                            <div className="ratio_left-cover-1 image-wrapper">
                                                <a href="/">
                                                    <img className="img-fluid w-100 h100"
                                                         src={m2}
                                                         alt="Image description"/>
                                                </a>
                                            </div>
                                            
                                            {/* <!--title--> */}
                                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                                {/* <!--title and description--> */}
                                                <a href="">
                                                    <h2 className="h3 post-title text-white my-1"></h2>
                                                </a>
    
                                                {/* <!-- meta title --> */}
                                                <div className="news-meta">
                                                    <span className="news-author"><a className="text-white font-weight-bold" href=""></a></span>
                                                    <span className="news-date"></span>
                                                </div>
                                            </div>
                                            {/* <!--end title--> */}
                                        </div>
                                        {/* <!--end thumbnail--> */}
                                    </div>
                                </div>
                                
                                {/* <!--Item slider--> */}
                                <div className="carousel-item">
                                    <div className="card border-0 rounded-0 text-light overflow zoom">
                                        {/* <!--thumbnail--> */}
                                        <div className="position-relative">
                                            {/* <!--thumbnail img--> */}
                                            <div className="ratio_left-cover-1 image-wrapper">
                                                <a href="/">
                                                    <img className="img-fluid w-100 h-100"
                                                         src={m3}
                                                         alt="Image description"/>
                                                </a>
                                            </div>
    
                                            {/* <!--title--> */}
                                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                                {/* <!--title and description--> */}
                                                <a href="/">
                                                    <h2 className="h3 post-title text-white my-1"></h2>
                                                </a>
    
                                                {/* <!-- meta title --> */}
                                                <div className="news-meta">
                                                    <span className="news-author"> <a className="text-white font-weight-bold" href=""></a></span>
                                                    <span className="news-date"></span>
                                                </div>
                                            </div>
                                            {/* <!--end title--> */}
                                        </div>
                                        {/* <!--end thumbnail--> */}
                                    </div>
                                </div>
                                
                                {/* <!--Item slider--> */}
                                <div className="carousel-item">
                                    <div className="card border-0 rounded-0 text-light overflow zoom">
                                        {/* <!--thumbnail--> */}
                                        <div className="position-relative">
                                            {/* <!--thumbnail img--> */}
                                            <div className="ratio_left-cover-1 image-wrapper">
                                                <a href="">
                                                    <img className="img-fluid w-100 h-100"
                                                         src={m4}
                                                         alt="Image description"/>
                                                </a>
                                            </div>
    
                                            {/* <!--title--> */}
                                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                                {/* <!--title and description--> */}
                                                <a href="">
                                                    <h2 className="h3 post-title text-white my-1"></h2>
                                                </a>
    
                                                {/* <!-- meta title --> */}
                                                <div className="news-meta">
                                                    <span className="news-author"><a className="text-white font-weight-bold" href=""></a></span>
                                                    <span className="news-date"></span>
                                                </div>
                                            </div>
                                            {/* <!--end title--> */}
                                        </div>
                                        {/* <!--end thumbnail--> */}
                                    </div>
                                </div>
                                {/* <!--end item slider--> */}
                            </div>
                            {/* <!--end carousel inner--> */}
                        </div>
                        {/* <!--navigation--> */}
                        <a className="carousel-control-prev" href="#featured1" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#featured1" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                    {/* <!--End slider news--> */}
                    
                    {/* <!--Start slider news--> */}
                    <div className="col-12 col-md-6 pb-0 pb-md-3 pt-2 pr-md-1">
                        <div id="featured" className="carousel slide carousel" data-ride="carousel">
                            {/* <!--slider navigate--> */}
                            <ol className="carousel-indicators top-indicator">
                                <li data-target="#featured" data-slide-to="0" className="active"></li>
                                <li data-target="#featured" data-slide-to="1"></li>
                                <li data-target="#featured" data-slide-to="2"></li>
                                <li data-target="#featured" data-slide-to="3"></li>
                            </ol>
                            
                            {/* <!--carousel inner--> */}
                            <div className="carousel-inner">
                                {/* <!--Item slider--> */}
                                <div className="carousel-item active">
                                    <div className="card border-0 rounded-0 text-light overflow zoom">
                                        {/* <!--thumbnail--> */}
                                        <div className="position-relative">
                                            {/* <!--thumbnail img--> */}
                                            <div className="ratio_left-cover-1 image-wrapper">
                                                <a href="/">
                                                    <img className="img-fluid w-100 h-100"
                                                         src={e1}
                                                         alt="Image description"/>
                                                </a>
                                            </div>
                                            
                                            {/* <!--title--> */}
                                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                                {/* <!--title and description--> */}
                                                <a href="/">
                                                    <h2 className="h3 post-title text-white my-1"></h2>
                                                </a>
    
                                                {/* <!-- meta title --> */}
                                                <div className="news-meta">
                                                    <span className="news-author"> <a className="text-white font-weight-bold" href="/"></a></span>
                                                    <span className="news-date"></span>
                                                </div>
                                            </div>
                                            {/* <!--end title--> */}
                                        </div>
                                        {/* <!--end thumbnail--> */}
                                    </div>
                                </div>
                                
                                {/* <!--Item slider--> */}
                                <div className="carousel-item">
                                    <div className="card border-0 rounded-0 text-light overflow zoom">
                                        {/* <!--thumbnail--> */}
                                        <div className="position-relative">
                                            {/* <!--thumbnail img--> */}
                                            <div className="ratio_left-cover-1 image-wrapper">
                                                <a href="/">
                                                    <img className="img-fluid w-100 h100"
                                                         src={e2}
                                                         alt="Image description"/>
                                                </a>
                                            </div>
                                            
                                            {/* <!--title--> */}
                                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                                {/* <!--title and description--> */}
                                                <a href="">
                                                    <h2 className="h3 post-title text-white my-1"></h2>
                                                </a>
    
                                                {/* <!-- meta title --> */}
                                                <div className="news-meta">
                                                    <span className="news-author"><a className="text-white font-weight-bold" href=""></a></span>
                                                    <span className="news-date"></span>
                                                </div>
                                            </div>
                                            {/* <!--end title--> */}
                                        </div>
                                        {/* <!--end thumbnail--> */}
                                    </div>
                                </div>
                                
                                {/* <!--Item slider--> */}
                                <div className="carousel-item">
                                    <div className="card border-0 rounded-0 text-light overflow zoom">
                                        {/* <!--thumbnail--> */}
                                        <div className="position-relative">
                                            {/* <!--thumbnail img--> */}
                                            <div className="ratio_left-cover-1 image-wrapper">
                                                <a href="/">
                                                    <img className="img-fluid w-100 h-100"
                                                         src={e3}
                                                         alt="Image description"/>
                                                </a>
                                            </div>
    
                                            {/* <!--title--> */}
                                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                                {/* <!--title and description--> */}
                                                <a href="/">
                                                    <h2 className="h3 post-title text-white my-1"></h2>
                                                </a>
    
                                                {/* <!-- meta title --> */}
                                                <div className="news-meta">
                                                    <span className="news-author"> <a className="text-white font-weight-bold" href=""></a></span>
                                                    <span className="news-date"></span>
                                                </div>
                                            </div>
                                            {/* <!--end title--> */}
                                        </div>
                                        {/* <!--end thumbnail--> */}
                                    </div>
                                </div>
                                
                                {/* <!--Item slider--> */}
                                <div className="carousel-item">
                                    <div className="card border-0 rounded-0 text-light overflow zoom">
                                        {/* <!--thumbnail--> */}
                                        <div className="position-relative">
                                            {/* <!--thumbnail img--> */}
                                            <div className="ratio_left-cover-1 image-wrapper">
                                                <a href="">
                                                    <img className="img-fluid w-100 h-100"
                                                         src={e4}
                                                         alt="Image description"/>
                                                </a>
                                            </div>
    
                                            {/* <!--title--> */}
                                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                                {/* <!--title and description--> */}
                                                <a href="">
                                                    <h2 className="h3 post-title text-white my-1"></h2>
                                                </a>
    
                                                {/* <!-- meta title --> */}
                                                <div className="news-meta">
                                                    <span className="news-author"><a className="text-white font-weight-bold" href=""></a></span>
                                                    <span className="news-date"></span>
                                                </div>
                                            </div>
                                            {/* <!--end title--> */}
                                        </div>
                                        {/* <!--end thumbnail--> */}
                                    </div>
                                </div>
                                {/* <!--end item slider--> */}
                            </div>
                            {/* <!--end carousel inner--> */}
                        </div>
                        {/* <!--navigation--> */}
                        <a className="carousel-control-prev" href="#featured" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#featured" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                    {/* <!--End slider news--> */}
                    
                 
                </section>
                {/* <!--END SECTION--> */}
            </div>
        </div>
    </div>
          
    
          </div>
        
        );
      }
    
}

export default QuotesComponent;
