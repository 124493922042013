import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import "../styles/homestyle.css";
import main1 from "../img/main1.png";
import main2 from "../img/main2.png";
import main3 from "../img/main3.png";
import main4 from "../img/main4.png";
import a_logo from "../img/aastitva-logo.png"
import quote from "../img/quote.png"
import aap_logo from "../img/aap_logo.png"
import blog from "../img/blog.png"

class HomeComponent extends Component {
  
  render() {
    return (
      <div>
        {/* <!--Container--> */}
<div className="container-fluid top_bottom_margin">
    <div className="row ">
        {/* <!--Start include wrapper--> */}
        <div className="include-wrapper pb-5 col-12">
            {/* <!--SECTION START--> */}
            <section className="row">
                {/* <!--Start slider news--> */}
                <div className="col-12 col-md-6 pb-0 pb-md-3 pt-2 pr-md-1">
                    <div id="featured" className="carousel slide carousel" data-ride="carousel">
                        {/* <!--slider navigate--> */}
                        <ol className="carousel-indicators top-indicator">
                            <li data-target="#featured" data-slide-to="0" className="active"></li>
                            <li data-target="#featured" data-slide-to="1"></li>
                            <li data-target="#featured" data-slide-to="2"></li>
                            <li data-target="#featured" data-slide-to="3"></li>
                        </ol>
                        
                        {/* <!--carousel inner--> */}
                        <div className="carousel-inner">
                            {/* <!--Item slider--> */}
                            <div className="carousel-item active">
                                <div className="card border-0 rounded-0 text-light overflow zoom">
                                    {/* <!--thumbnail--> */}
                                    <div className="position-relative">
                                        {/* <!--thumbnail img--> */}
                                        <div className="ratio_left-cover-1 image-wrapper">
                                            <a href="/">
                                                <img className="img-fluid w-100 h-100"
                                                     src={main1}
                                                     alt=""/>
                                            </a>
                                        </div>
                                        
                                        {/* <!--title--> */}
                                        <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                            {/* <!--title and description--> */}
                                            <a href="/">
                                                <h2 className="h3 post-title text-white my-1"></h2>
                                            </a>

                                            {/* <!-- meta title --> */}
                                            <div className="news-meta">
                                                <span className="news-author"> <a className="text-white font-weight-bold" href="/"></a></span>
                                                <span className="news-date"></span>
                                            </div>
                                        </div>
                                        {/* <!--end title--> */}
                                    </div>
                                    {/* <!--end thumbnail--> */}
                                </div>
                            </div>
                            
                            {/* <!--Item slider--> */}
                            <div className="carousel-item">
                                <div className="card border-0 rounded-0 text-light overflow zoom">
                                    {/* <!--thumbnail--> */}
                                    <div className="position-relative">
                                        {/* <!--thumbnail img--> */}
                                        <div className="ratio_left-cover-1 image-wrapper">
                                            <a href="/">
                                                <img className="img-fluid w-100 h100"
                                                     src={main2}
                                                     alt="Image description"/>
                                            </a>
                                        </div>
                                        
                                        {/* <!--title--> */}
                                        <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                            {/* <!--title and description--> */}
                                            <a href="">
                                                <h2 className="h3 post-title text-white my-1"></h2>
                                            </a>

                                            {/* <!-- meta title --> */}
                                            <div className="news-meta">
                                                <span className="news-author"><a className="text-white font-weight-bold" href=""></a></span>
                                                <span className="news-date"></span>
                                            </div>
                                        </div>
                                        {/* <!--end title--> */}
                                    </div>
                                    {/* <!--end thumbnail--> */}
                                </div>
                            </div>
                            
                            {/* <!--Item slider--> */}
                            <div className="carousel-item">
                                <div className="card border-0 rounded-0 text-light overflow zoom">
                                    {/* <!--thumbnail--> */}
                                    <div className="position-relative">
                                        {/* <!--thumbnail img--> */}
                                        <div className="ratio_left-cover-1 image-wrapper">
                                            <a href="/">
                                                <img className="img-fluid w-100 h-100"
                                                     src={main3}
                                                     alt="Image description"/>
                                            </a>
                                        </div>

                                        {/* <!--title--> */}
                                        <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                            {/* <!--title and description--> */}
                                            <a href="/">
                                                <h2 className="h3 post-title text-white my-1"></h2>
                                            </a>

                                            {/* <!-- meta title --> */}
                                            <div className="news-meta">
                                                <span className="news-author"> <a className="text-white font-weight-bold" href=""></a></span>
                                                <span className="news-date"></span>
                                            </div>
                                        </div>
                                        {/* <!--end title--> */}
                                    </div>
                                    {/* <!--end thumbnail--> */}
                                </div>
                            </div>
                            
                            {/* <!--Item slider--> */}
                            <div className="carousel-item">
                                <div className="card border-0 rounded-0 text-light overflow zoom">
                                    {/* <!--thumbnail--> */}
                                    <div className="position-relative">
                                        {/* <!--thumbnail img--> */}
                                        <div className="ratio_left-cover-1 image-wrapper">
                                            <a href="">
                                                <img className="img-fluid w-100 h-100"
                                                     src={main4}
                                                     alt="Image description"/>
                                            </a>
                                        </div>

                                        {/* <!--title--> */}
                                        <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                            {/* <!--title and description--> */}
                                            <a href="">
                                                <h2 className="h3 post-title text-white my-1"></h2>
                                            </a>

                                            {/* <!-- meta title --> */}
                                            <div className="news-meta">
                                                <span className="news-author"><a className="text-white font-weight-bold" href=""></a></span>
                                                <span className="news-date"></span>
                                            </div>
                                        </div>
                                        {/* <!--end title--> */}
                                    </div>
                                    {/* <!--end thumbnail--> */}
                                </div>
                            </div>
                            {/* <!--end item slider--> */}
                        </div>
                        {/* <!--end carousel inner--> */}
                    </div>
                    {/* <!--navigation--> */}
                    <a className="carousel-control-prev" href="#featured" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#featured" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                {/* <!--End slider news--> */}
                
                {/* <!--Start box news--> */}
                <div className="col-12 col-md-6 pt-2 pl-md-1 mb-3 mb-lg-4">
                    <div className="row">
                        {/* <!--news box--> */}
                        <div className="col-6 pb-1 pt-0 pr-1">
                            <div className="card border-0 rounded-0 text-white overflow zoom">
                                {/* <!--thumbnail--> */}
                                <div className="position-relative">
                                    {/* <!--thumbnail img--> */}
                                    <div className="ratio_right-cover-2 image-wrapper">
                                        <a href=" ">
                                            <img className="img-fluid w-75"
                                                 src={aap_logo}
                                                 alt="Image description"/>
                                        </a>
                                    </div>
                                    
                                    {/* <!--title--> */}
                                    <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                        {/* <!-- category --> */}
                                        <a className="p-1 badge badge-primary rounded-0" href=" "></a>

                                        {/* <!--title and description--> */}
                                        <a href=" ">
                                            <h2 className="h5 text-white my-1">Political Affiliation</h2>
                                        </a>
                                    </div>
                                    {/* <!--end title--> */}
                                </div>
                                {/* <!--end thumbnail--> */}
                            </div>
                        </div>
                        
                        {/* <!--news box--> */}
                        <div className="col-6 pb-1 pl-1 pt-0">
                            <div className="card border-0 rounded-0 text-white overflow zoom">
                                {/* <!--thumbnail--> */}
                                <div className="position-relative">
                                    {/* <!--thumbnail img--> */}
                                    <div className="ratio_right-cover-2 image-wrapper">
                                        <a href=" ">
                                            <img className="img-fluid w-75"
                                                 src={a_logo}
                                                 alt="Image description"/>
                                        </a>
                                    </div>
                                    
                                    {/* <!--title--> */}
                                    <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                        {/* <!-- category --> */}
                                        <a className="p-1 badge badge-primary rounded-0" href=" "></a>

                                        {/* <!--title and description--> */}
                                        <a href=" ">
                                            <h2 className="h5 text-white my-1">Social Affiliation</h2>
                                        </a>
                                    </div>
                                    {/* <!--end title--> */}
                                </div>
                                {/* <!--end thumbnail--> */}
                            </div>
                        </div>
                        
                        {/* <!--news box--> */}
                        <div className="col-6 pb-1 pr-1 pt-1">
                            <div className="card card1 border-0 rounded-0 text-white overflow zoom">
                                {/* <!--thumbnail--> */}
                                <div className="position-relative">
                                    {/* <!--thumbnail img--> */}
                                    <div className="ratio_right-cover-2 image-wrapper">
                                        <Link to="/quotes">
                                            <img className="img-fluid w-50"
                                                 src={quote}
                                                 alt="Image description"/>
                                        </Link>
                                    </div>
                                    
                                    {/* <!--title--> */}
                                    <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                        {/* <!-- category --> */}
                                        <a className="p-1 badge badge-primary rounded-0" href=" "></a>

                                        {/* <!--title and description--> */}
                                        <Link to="/quotes">
                                            <h2 className="h5 text-white my-1">Quotes</h2>
                                        </Link>
                                    </div>
                                    {/* <!--end title--> */}
                                </div>
                                {/* <!--end thumbnail--> */}
                            </div>
                        </div>
                        
                        {/* <!--news box--> */}
                        <div className="col-6 pb-1 pl-1 pt-1">
                            <div className="card card2 border-0 rounded-0 text-white overflow zoom">
                                {/* <!--thumbnail--> */}
                                <div className="position-relative">
                                    {/* <!--thumbnail img--> */}
                                    <div className="ratio_right-cover-2 image-wrapper">
                                        <a href="http://chetanbendre.wordpress.com"  target="blank" rel="noopener noreferrer">
                                            <img className="img-fluid w-50"
                                                 src={blog}
                                                 alt="Image description"/>
                                        </a>
                                    </div>
                                    
                                    {/* <!--title--> */}
                                    <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                                        {/* <!-- category --> */}
                                        <a className="p-1 badge badge-primary rounded-0" href=" "></a>

                                        {/* <!--title and description--> */}
                                        <a href="http://chetanbendre.wordpress.com" target="blank" rel="noopener noreferrer">
                                            <h2 className="h5 text-white my-1">Blogs</h2>
                                        </a>
                                    </div>
                                    {/* <!--end title--> */}
                                </div>
                                {/* <!--end thumbnail--> */}
                            </div>
                        </div>
                        {/* <!--end news box--> */}
                    </div>
                </div>
                {/* <!--End box news--> */}
            </section>
            {/* <!--END SECTION--> */}
        </div>
    </div>
</div>
      
<div className="jumbotron jumbotron-fluid">
  <div className="container">
    <h3>Connect on Social Media </h3>    
    <div className='row'>
            
        <div className= "col-12 col-sm-6 pb-0 pb-md-3 pt-2 pr-md-1">
            
            <div className="fb-page" data-href="https://www.facebook.com/chetan.bendre" 
                data-height="500"
                data-tabs="timeline, events" data-small-header="false" data-adapt-container-width="true"
                data-hide-cover="false" data-show-facepile="true">
                <blockquote cite="https://www.facebook.com/chetan.bendre" className="fb-xfbml-parse-ignore">
                    <a href="https://www.facebook.com/chetan.bendre">
                    Chetan Gautam Bendre
                    </a>
                </blockquote>
            </div>
        </div>

                
        <div className= "col-12 col-md-6 pb-0 pb-md-3 pt-2 pr-md-1">
        <a className="twitter-timeline" data-height="500" 
        data-theme="light" data-link-color="#E95F28"
         href="https://twitter.com/chetan_g_bendre?ref_src=twsrc%5Etfw">Tweets by chetan_g_bendre</a>  
        </div>
        
    </div>
  </div>
</div>

      </div>
    
    );
  }

}

export default HomeComponent;
