import React, { Component } from "react";
import "../styles/connectstyle.css";
import "../styles/commonstyle.css";


class ConnectComponent extends Component {
  url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyBu-yhaJW4JqzUo8A6yC_yGww_mvsn1Fdc&q=Chetan+Gautam+Bendre"
  render() {
    return (
      <div>
             {/* <!--Container--> */}
              <div className="container-fluid top_bottom_margin">
                  <div className="row ">
                      {/* <!--Start include wrapper--> */}
                      <div className="include-wrapper pb-5 col-12">
                          {/* <!--SECTION START--> */}
                          <section className="row">
                              {/* <!--Start slider news--> */}
                              <div className="col-12 col-md-12 pb-0 pb-md-3 pt-2 pr-md-1">
                              <iframe width="100%" height="500" title="" frameBorder="0"  
                        src={this.url} ></iframe>
                              </div>
                              {/* <!--End slider news--> */}
                              
                              
                          </section>
                          {/* <!--END SECTION--> */}
                      </div>
                  </div>
              </div>
          
          <div className="row text-center">
              <div className="col-sm-4 box1 pt-4">
                <a href="tel:+91 9890203097"><i className="fa fa-mobile fa-3x"></i>
                <h3 className="d-block">Mobile</h3>
                <p className="d-block">+91 9890203097</p></a>
              </div>
              <div className="col-sm-4 box2 pt-4">
                <a href=""><i className="fa fa-home fa-3x"></i>
                <h3 className="d-block">Address</h3>
                <p className="d-block">Panchtaranagar, Akurdi, Pune</p></a>
              </div>
              <div className="col-sm-4 box3 pt-4">
                <a href="mailto:chetan1109@gmail.com"><i className="fa fa-envelope fa-3x"></i>
                <h3 className="d-block">E-mail</h3>
                <p className="d-block">chetan1109@gmail.com</p></a>
              </div>
          </div>

      </div>
    );
  }
}

export default ConnectComponent;
